<template>
    <div>
        <breadcrumbs
            :title="'Administratori sustava'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>

        <div class="ibox">

            <div class="ibox-title">
                <h5>Administratori sustava</h5><br>
                <button
                    v-if="admin && [2,3].includes( admin.admin_permission_id )"
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi administrator
                </button>
            </div>

            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                ></datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../components/datatable/Datatables";
import toastr from "toastr";
import axios from "axios";
export default {
    name: "Admins",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            breadcrumbs: [],

            admin: null,

            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'admin',
            datatables: {},

        }
    },

    methods: {

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/admins',
                    name: 'Administratori sustava'
                }
            ]
        },

        create(){
            this.$router.push( { path: '/admin/create' } )
        },

        rowClick( row ){
            if( [2,3].includes( this.admin.admin_permission_id ) ){
                this.$router.push( { path: '/admin/' + row.id + '/edit' } )
            } else {
                toastr.error( 'Nemate ovlasti!' )
            }

        },

        async getAdmin(){
            let vm = this
            await axios
                .get(process.env.VUE_APP_API_URL + "admin-auth" )
                .then(response => {
                    vm.admin = response.data
                })
                .catch(() => {
                    vm.admin = null
                });
        },

        async setupDatatable() {

            let vm = this;

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'title': 'ID',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'first_name',
                            'title': 'Ime',
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'last_name',
                            'title': 'Prezime',
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'valid_from',
                            'title': 'Vrijedi od',
                            'column_visibility_group': {
                                id: 'user',
                            },
                            'filter': {
                                'type': 'date'
                            }
                        },
                        {
                            'id': 'valid_to',
                            'title': 'Vrijedi do',
                            'column_visibility_group': {
                                id: 'user',
                            },
                            'filter': {
                                'type': 'date'
                            }
                        },
                        {
                            'id': 'admin_permission_id',
                            'title': 'Ovlasti',
                            'type': function ( row ){
                                return row.permission ? row.permission.name : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;


                                    axios.get( process.env.VUE_APP_API_URL +  'admin-permission', )
                                        .then( function ( response ) {
                                            let tmp = [];
                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.name
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'user' : 'Administratori sustava'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Administratori sustava',
            }

            vm.show_datatable = true;

        }

    },
    async mounted() {
        this.setBreadcrumbs()
        await this.getAdmin()
        await this.setupDatatable()
    }
};
</script>

<style scoped>

</style>
