<template>
    <div>

        <breadcrumbs
            :title="'Izbori'"
            :breadcrumbs="allBreadcrumbs"
        ></breadcrumbs>

        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                :class="{
                    'navbar-brand btn btn-light btn-xl': true,
                    'active': active_route === route
                }"
                @click="open(route)"
            >
                <strong>Izbori</strong>
            </a>

            <ul class="navbar-nav mr-auto">

                <li v-if="admin.admin_permission_id !== 1" class="nav-item">
                    <a
                        role="button"
                        :class="{
                            'nav-link btn btn-light': true,
                            'active': active_route === route + '/election-committee'
                        }"
                        @click.prevent="open( route + '/election-committee' )"
                    >
                        <strong>Izborna povjerenstva</strong>
                    </a>
                </li>

                <li v-if="admin.admin_permission_id !== 1 " class="nav-item">
                    <a
                        role="button"
                        :class="{
                            'nav-link btn btn-light': true,
                            'active': active_route === route + '/admin-election-committee'
                        }"
                        @click.prevent="open( route + '/admin-election-committee' )"
                    >
                        <strong>Članovi izbornih povjerenstava</strong>
                    </a>
                </li>

                <li v-if="admin.admin_permission_id !== 1 " class="nav-item">
                    <a
                        role="button"
                        :class="{
                            'nav-link btn btn-light': true,
                            'active': active_route === route + '/candidate-list'
                        }"
                        @click.prevent="open( route + '/candidate-list' )"
                    >
                        <strong>Kandidacijse liste</strong>
                    </a>
                </li>

                <li class="nav-item">
                    <a
                        role="button"
                        :class="{
                            'nav-link btn btn-light': true,
                            'active': active_route === route + '/voter'
                        }"
                        @click.prevent="open( route + '/voter' )"
                    >
                        <strong>Popis birača</strong>
                    </a>
                </li>

                <li v-if="admin.admin_permission_id !== 1" class="nav-item">
                    <a
                        role="button"
                        :class="{
                            'nav-link btn btn-light': true,
                            'active': active_route === route + '/emailing'
                        }"
                        @click.prevent="open( route + '/emailing' )"
                    >
                        <strong>Emailing</strong>
                    </a>
                </li>

                <!--<li class="nav-item">
                    <a
                        role="button"
                        :class="{
                            'nav-link btn btn-light': true,
                            'active': active_route === route + '/reports'
                        }"
                        @click.prevent="open( route + '/reports' )"
                    >
                        <strong>Rezultati</strong>
                    </a>
                </li>-->

            </ul>

        </nav>


    </div>
</template>

<script>

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
    name: "ElectionNavigation",
    components: {Breadcrumbs},
    props:[
        'admin',
        'id',
        'breadcrumbs'
    ],
    data(){
        return {
            election : null,
            naziv: '',
            route: '/election/' + this.id,
            active_route: '',
            allBreadcrumbs: []
        }
    },
    methods:{
        open( name ){
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        async getElection(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'election' + '/' + vm.id )
                .then( function ( response ) {
                    vm.election = response.data
                    vm.setBreadcrumbs()
                } )
                .catch( error => { console.log( error ) } )
        },

        setBreadcrumbs(){
        let vm = this

            vm.allBreadcrumbs = [
                    {
                        path: '/election',
                        name: 'Izbori'
                    },
                    {
                        path: ( '/election/' + this.id ),
                        name: this.election ? this.election.name : ''
                    },
                ]

                vm.breadcrumbs.forEach( function( b ){
                    vm.allBreadcrumbs.push( b )
                } )
        }
    },
    async mounted() {
        this.active_route = this.$route.path
        await this.getElection()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                this.setBreadcrumbs()
            }
        },
    },
};
</script>

<style scoped>

</style>
