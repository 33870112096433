<template>
    <div>

        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        >
        </election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Emailing</h5><br>
            </div>
            <div class="ibox-content">
                <forms
                    :settings="settings"
                    @onFormButtonClick="onFormButtonClick"
                    @onInput="onInput"
                ></forms>
            </div>
        </div>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Emailing</h5><br>
                <button class="btn btn-primary" @click="sendMail('all')">Pošalji sve</button>
                <button class="btn btn-danger" @click="deleteMail('all')">Obriši sve</button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @buttonClick="datatableButtonClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import Loading from "vue-loading-overlay";
import toastr from "toastr";
import 'vue-loading-overlay/dist/vue-loading.css';
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation.vue";
export default {
    name: "Emailing",
    components: {ElectionNavigation, Datatables, Forms, Loading },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,

            open_loader: false,

            // datatable
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'emailing',
            datatables: {},
            breadcrumbs: [],

            // form
            settings: {},
            errors: {},
            data: {},

            // select options
            voter_select: []
        }
    },
    methods:{

        async init(){
            this.open_loader = true
            await this.fetchVoters()
            this.setupForm()
            this.setDatatable()
            this.setBreadcrumbs()
            this.open_loader = false
        },


        async fetchVoters(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'voter', {
                params:{
                    page: 1,
                    per_page: -1,
                    filters: {
                        election_id: vm.election_id,
                        access_to_voting: '1'
                    }
                }
            } )
                .then( response => {
                    vm.voter_select.push( {
                        'key': 'all',
                        'value': 'Svi'
                    } )
                    response.data.data.forEach( item => {
                        // let exists = vm.getVoter( [item.id.toString()] )
                        //
                        // if( !(exists)){
                            vm.voter_select.push( {
                                key: item.id.toString(),
                                value: item.first_name + ' ' + item.last_name + ' (' + item.vat + ')'
                            } )
                        // }
                    })
                } )
                .catch( error => console.log( error ) )
        },


        getVoter( ids ){
            let ret = []
            if( ids && ids.length )
                ids.forEach( id => {
                    ret.push(this.voter_select.find( item => item.key === id ))
                })
            return ret;

        },


        setupForm(){

            let vm = this

            let cancel_button = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
            }

            let generate_button = {
                id: 'add',
                title: 'Dodaj',
                type: 'primary',
            }

            let buttons = []
            buttons.push( cancel_button )
            if(
                vm.data.voter_ids && vm.data.voter_ids.length
            )
                buttons.push( generate_button )


            let voter_ids_input = {
                name: 'voter_ids',
                type: 'select',
                title: 'Dodaj na listu za slanje',
                value: vm.getVoter( vm.data.voter_ids ),
                width: 12,
                error: vm.errors['voter_ids'] ? vm.errors['voter_ids'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.voter_select,
                    title_width: 3,
                    input_width: 9,
                    multiple: true
                }
            }



            let inputs = []
            inputs.push( voter_ids_input )

            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            },
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            let vm = this;
            vm.data[name] = value
            vm.setupForm()
        },


        onFormButtonClick( id, action, config, data ){
            let vm = this
            if( id === 'add' ){
                vm.open_loader = true
                axios.post( process.env.VUE_APP_API_URL + 'emailing/add-to-mailing-list' , {
                    election_id: vm.election_id,
                    voter_ids: data.voter_ids
                } )
                    .then( () => {
                        toastr.success( 'Uspješno kreirano!' )
                        vm.onInput( 'voter_ids', [] )
                        vm.open_loader = false
                        vm.setDatatable()
                    } )
                    .catch( () => {
                        vm.open_loader = false
                        toastr.error( 'Došlo je do greške!' )
                        vm.setDatatable()
                    } )
            } else if ( id === 'cancel' ){
                vm.data = {}
                vm.setupForm()
            }
        },



        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'election_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.election_id
                            },
                        },
                        {
                            'id': 'first_name',
                            'title': 'Ime',
                            'type' : function( row ){
                                return row.voter ? row.voter.first_name : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'last_name',
                            'title': 'Prezime',
                            'type' : function( row ){
                                return row.voter ? row.voter.last_name : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'vat',
                            'title': 'OIB',
                            'type' : function( row ){
                                return row.voter ? row.voter.vat : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'searchable': false,
                            'orderable': false,
                            'type' : function( row ){
                                return row.voter ? row.voter.email : ''
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true,
                            }
                        },
                        {
                            'id': 'send',
                            'title': '',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'emailing',
                            },
                            config: {
                                title: 'Pošalji',
                                type: 'primary',
                            }
                        },
                        {
                            'id': 'delete',
                            'title': '',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'emailing',
                            },
                            config: {
                                title: 'Obriši',
                                type: 'danger',
                            }
                        },
                        {
                            'id': 'approved',
                            'title': 'Potvrđeno slanje',
                            'type' : function( row ){
                                return row.approved ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    },
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sent',
                            'title': 'Poslano',
                            'type' : function( row ){
                                return row.sent ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    },
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'success',
                            'title': 'Uspješno poslano',
                            'type' : function( row ){
                                return row.success ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    },
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sent_datetime',
                            'title': 'Datum',
                            'type' : 'text',
                            'searchable': false,
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'log',
                            'title': 'Log',
                            'searchable': false,
                            'type' : function( row ){
                                return row.log
                            },
                            'column_visibility_group': {
                                'id': 'emailing',
                                'visible' : true
                            }
                        },
                    ],
                    column_visibility_groups:{
                        'emailing' : 'Emailing'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Emailing',
            }
            vm.show_datatable = true;
        },


        async datatableButtonClick( col, row ){
            if( col.id === 'send' ){
                if( row.poslano ){
                    toastr.error( 'Email je već poslan!')
                } else {
                    await this.sendMail( row.id )
                }
            } else if( col.id === 'delete' ){
                if( row.poslano ){
                    toastr.error( 'Email je već poslan i ne može se brisati!')
                } else {
                    await this.deleteMail( row.id )
                }
            }
        },

        async sendMail( id ){
            let vm = this;
            vm.open_loader = true;
            await axios.post( process.env.VUE_APP_API_URL + 'emailing/' + 'send', {
                election_id: vm.election_id,
                id
            })
                .then( response => {
                    console.log( response )
                    vm.setDatatable()
                    vm.open_loader = false;
                })
                .catch( error => {
                    console.log( error )
                    vm.open_loader = false;
                })
        },

        async deleteMail( id ){
            let vm = this;
            vm.open_loader = true;
            await axios.post( process.env.VUE_APP_API_URL + 'emailing/delete', {
                election_id: vm.election_id,
                id
            } )
                .then( response => {
                    console.log( response )
                    vm.setDatatable()
                    vm.open_loader = false;
                })
                .catch( error => {
                    console.log( error )
                    vm.open_loader = false;
                })
        },


        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/emailing',
                    name: 'Emailing'
                }
            ]
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
