<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Članovi izbornih povjerenstava</h5><br>
                <button
                    v-if="admin.admin_permission_id !== 1 "
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi član izbornog povjerenstva
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import Datatables from "@/components/datatable/Datatables";
export default {
    name: "AdmminElectionCommittee",
    components: { ElectionNavigation, Datatables },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: [],

            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'admin-election-committee',
            datatables: {},
        }
    },

    methods:{

        rowClick( row ){
            if(this.admin && [2,3].includes( this.admin.admin_permission_id ) )
                this.$router.push( { path: '/election/' + this.election_id + '/admin-election-committee/' + row.id + '/edit' } )
        },

        create(){
            this.$router.push( { path: '/election/' + this.election_id + '/admin-election-committee/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'election_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.election_id
                            },
                        },
                        {
                            'id': 'election_committee_name',
                            'title': 'Izborno povjrenestvo',
                            'type': function( row ){
                                return row.election_committee ? row.election_committee.name : ''
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'role_name',
                            'title': 'Uloga',
                            'type': function( row ){
                                return row.role ? row.role.name : ''
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'first_name',
                            'title': 'Ime',
                            'type': function( row ){
                                return row.first_name
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'last_name',
                            'title': 'Prezime',
                            'type': function( row ){
                                return row.last_name
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'type': function( row ){
                                return row.email
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'active',
                            'title': 'Aktivan',
                            'type': function( row ){
                                return row.active ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        'key': '1',
                                        'value': 'Da'
                                    },
                                    {
                                        'key': '0',
                                        'value': 'Ne'
                                    }
                                ]
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'active_from',
                            'title': 'Aktivan od',
                            'searchable' : false,
                            'type': function( row ){
                                return row.active_from
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'active_to',
                            'title': 'Aktivan do',
                            'searchable' : false,
                            'type': function( row ){
                                return row.active_to
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'vat',
                            'title': 'OIB',
                            'searchable' : false,
                            'type': function( row ){
                                return row.vat
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'phone',
                            'title': 'Mobitel',
                            'searchable' : false,
                            'type': function( row ){
                                return row.phone
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'authorization_number',
                            'title': 'Broj ovlaštenja',
                            'searchable' : false,
                            'type': function( row ){
                                return row.authorization_number
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'address',
                            'title': 'Adresa',
                            'searchable' : false,
                            'type': function( row ){
                                return row.address
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'place_of_residence',
                            'title': 'Mjesto',
                            'searchable' : false,
                            'type': function( row ){
                                return row.place_of_residence
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },
                        {
                            'id': 'admin_id',
                            'title': 'Login pristup',
                            'searchable' : false,
                            'type': function( row ){
                                return row.admin ? 'Da' : 'Ne'
                            },
                            'column_visibility_group': {
                                id: 'admin_election_committee',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'admin_election_committee' : 'Izborna povjerenstva'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Članovi izbornog povjerenstva',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/admin-election-committee',
                    name: 'Članovi izbornog povjerenstva'
                }
            ]
        }

    },

    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
