<template>
    <div>
        <breadcrumbs
            :title="'Administrator'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Uredi administratota: {{ admin_name }}</h5>
            </div>
            <div class="ibox-content">
                <admin-form
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                ></admin-form>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import AdminForm from "./AdminForm";
export default {
    name: "AdminEdit",
    components: { AdminForm, Breadcrumbs },
    data(){
        return {
            id: this.$route.params.admin_id,
            breadcrumbs: [],
            admin_name: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/admin',
                    name: 'Administratori susatava'
                },
                {
                    path: ( '/admin/' + this.id + '/edit' ),
                    name: ( 'Uređivanje administratora: ' + this.name  )
                }
            ]
        },
        setData( data ){
            this.admin_name = data.first_name + ' ' + data.last_name
        }
    }
};
</script>

<style scoped>

</style>
