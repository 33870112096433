<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import Forms from "@/components/form/Forms";
import toastr from "toastr";
import axios from "axios";

export default {
    name: "ElectionCommitteeForm",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        election_id: {
            default: null
        }
    },
    data(){
        return {
            settings: {},
            errors:{},
            route: '/election/' + this.election_id + '/voter',
            url: 'voter',
            input_data:{},

            election_committee_select: [],

            access_to_voting_select: [
                {
                    'key': '1',
                    'value': 'Da'
                },
                {
                    'key' : '0',
                    'value': 'Ne'
                }
            ],
        }
    },

    methods: {

        async init(){
            let vm = this


            if ( vm.action === 'edit' || vm.action === 'show' ){

                await axios.get( process.env.VUE_APP_API_URL + 'voter/' + vm.id,)
                    .then( response => {
                        vm.input_data['election_committee_id'] = response.data.election_committee_id
                        vm.input_data['first_name'] = response.data.first_name
                        vm.input_data['last_name'] = response.data.last_name
                        vm.input_data['email'] = response.data.email
                        vm.input_data['vat'] = response.data.vat
                        vm.input_data['academic_degree'] = response.data.academic_degree
                        vm.input_data['professional_degree'] = response.data.professional_degree
                        vm.input_data['authorization_number'] = response.data.authorization_number
                        vm.input_data['status'] = response.data.status
                        vm.input_data['region'] = response.data.region
                        vm.input_data['address'] = response.data.address
                        vm.input_data['place_of_residence'] = response.data.place_of_residence
                        vm.input_data['gender'] = response.data.gender
                        vm.input_data['access_to_voting'] = response.data.access_to_voting.toString()
                        vm.input_data['group_name'] = response.data.group_name
                        vm.input_data['voted'] = response.data.voted.toString()

                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( error => { console.log( error ) } )

            } else if ( vm.action === 'create' ) {
                vm.input_data = {}
                vm.setupForm()
            }
        },


        async fetchElectionCommittee(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        election_id : vm.election_id,
                        constituency : '1'

                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.election_committee_select =
                        response.data.data.map( function( item ) {
                            return { key: item.id, value: item.name }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        getElectionCommitteeByID( id ){
            return this.election_committee_select.find( item => item.key === id )
        },

        getAccessToVotingByID( id ){
            return this.access_to_voting_select.find( item => item.key === id )
        },



        setupForm(){
            let vm = this

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }


            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_delete )
                buttons.push( button_update)
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = []

            let election_id_input = {
                name: 'election_id',
                type: 'hidden',
                title: 'Naziv',
                value: vm.election_id,
                width: 12,
                error: vm.errors['election_id'] ? vm.errors['election_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( election_id_input )

            let first_name_input = {
                name: 'first_name',
                type: 'input',
                title: 'Ime',
                value: vm.input_data.first_name,
                width: 12,
                error: vm.errors['first_name'] ? vm.errors['first_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( first_name_input )

            let last_name_input = {
                name: 'last_name',
                type: 'input',
                title: 'Prezime',
                value: vm.input_data.last_name,
                width: 12,
                error: vm.errors['last_name'] ? vm.errors['last_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( last_name_input )

            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value: vm.input_data.email,
                width: 12,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( email_input )

            let vat_input = {
                name: 'vat',
                type: 'input',
                title: 'OIB',
                value: vm.input_data.vat,
                width: 12,
                error: vm.errors['vat'] ? vm.errors['vat'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( vat_input )

            let academic_degree_input = {
                name: 'academic_degree',
                type: 'input',
                title: 'Akademski stupanj',
                value: vm.input_data.academic_degree,
                width: 12,
                error: vm.errors['academic_degree'] ? vm.errors['academic_degree'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( academic_degree_input )

            let professional_degree_input = {
                name: 'professional_degree',
                type: 'input',
                title: 'Stručni stupanj',
                value: vm.input_data.professional_degree,
                width: 12,
                error: vm.errors['professional_degree'] ? vm.errors['professional_degree'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( professional_degree_input )

            let authorization_number_input = {
                name: 'authorization_number',
                type: 'input',
                title: 'Broj ovlaštenja',
                value: vm.input_data.authorization_number,
                width: 12,
                error: vm.errors['authorization_number'] ? vm.errors['authorization_number'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( authorization_number_input )

            let status_input = {
                name: 'status',
                type: 'input',
                title: 'Status',
                value: vm.input_data.status,
                width: 12,
                error: vm.errors['status'] ? vm.errors['status'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( status_input )

            let region_input = {
                name: 'region',
                type: 'input',
                title: 'Županija',
                value: vm.input_data.region,
                width: 12,
                error: vm.errors['region'] ? vm.errors['region'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( region_input )

            let address_input = {
                name: 'address',
                type: 'input',
                title: 'Adresa',
                value: vm.input_data.address,
                width: 12,
                error: vm.errors['address'] ? vm.errors['address'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( address_input )

            let place_of_residence_input = {
                name: 'place_of_residence',
                type: 'input',
                title: 'Mjesto',
                value: vm.input_data.place_of_residence,
                width: 12,
                error: vm.errors['place_of_residence'] ? vm.errors['place_of_residence'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( place_of_residence_input )

            let gender_input = {
                name: 'gender',
                type: 'input',
                title: 'Spol',
                value: vm.input_data.gender,
                width: 12,
                error: vm.errors['gender'] ? vm.errors['gender'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( gender_input )

            let election_cimmittee_id_input = {
                name: 'election_committee_id',
                type: 'select',
                title: 'Izborno povjerenstvo',
                value: vm.input_data.election_committee_id ? vm.getElectionCommitteeByID( vm.input_data.election_committee_id ) : '',
                width: 12,
                error: vm.errors['election_committee_id'] ? vm.errors['election_committee_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.election_committee_select
                }
            }
            inputs.push( election_cimmittee_id_input )


            let access_to_voting_input = {
                name: 'access_to_voting',
                type: 'select',
                title: 'Pravo glasanja',
                value: vm.input_data.access_to_voting ? vm.getAccessToVotingByID( vm.input_data.access_to_voting ) : '',
                width: 12,
                error: vm.errors['access_to_voting'] ? vm.errors['access_to_voting'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.access_to_voting_select
                }
            }
            inputs.push( access_to_voting_input )


            let group_name_input = {
                name: 'group_name',
                type: 'input',
                title: 'Grupa',
                value: vm.input_data.group_name,
                width: 12,
                error: vm.errors['group_name'] ? vm.errors['group_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( group_name_input )


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            if( this.input_data[ name ] !== value ){
                this.input_data[ name ] = value
                this.setupForm()
            }
        },


        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route  } )
                } else {
                    if( data.messages.election_id )
                        toastr.error( data.messages.election_id )
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                if( data.success ){
                    toastr.error( 'Uspješno obrisano!' )
                    this.$router.push({ path: this.route } )
                } else {
                    if( data.messages.election_id )
                        toastr.error( data.messages.election_id )
                    else
                        toastr.error( 'Došlo je do greške' )
                }
            }

        },
    },

    async mounted() {
        await this.fetchElectionCommittee()
        await this.init()
    }
};
</script>

<style scoped>

</style>
