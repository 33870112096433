<template>
    <div>
        <router-view
            v-if="admin"
            :admin="admin"
        ></router-view>
        <br>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ElectionBase",
    components: {},
    data(){
        return {
            admin: null,
        }
    },
    mounted() {
        let vm = this
        axios.get( process.env.VUE_APP_API_URL + 'admin-auth' )
            .then( response => { vm.admin = response.data } )
            .catch( error => { console.log( error ) } )
    }
};
</script>

<style scoped>

</style>
