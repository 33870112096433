<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova kandidacijska lista</h5>
            </div>
            <div class="ibox-content">
                <candidate-list-form
                    :disabloed="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                    :election_id="election_id"
                ></candidate-list-form>
            </div>
        </div>

    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import CandidateListForm from "@/views/pages/Election/CandidateList/CandidateListForm";
export default {
    name: "CandidateListCreate",
    components: { CandidateListForm, ElectionNavigation },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: []
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/candidate-list',
                    name: 'Kandidacijske liste'
                },
                {
                    path: '/election/' + this.election_id + '/candidate-list/create',
                    name: 'Nova kandidacijska lista'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
