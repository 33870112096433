<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Novo izborno povjerenstvo</h5>
            </div>
            <div class="ibox-content">
                <election-committee-form
                    :disabloed="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                    :election_id="election_id"
                ></election-committee-form>
            </div>
        </div>

    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import ElectionCommitteeForm from "@/views/pages/Election/ElectionCommittee/ElectionCommitteeForm";
export default {
    name: "ElectionCommitteeCreate",
    components: { ElectionCommitteeForm, ElectionNavigation },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: []
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/election-committee',
                    name: 'Izborna povjerenstva'
                },
                {
                    path: '/election/' + this.election_id + '/election-committee/create',
                    name: 'Novo izborno povjerenstvo'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
