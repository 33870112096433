<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import Forms from "../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";

export default {
    name: "ElectionForm",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/election',
            url: 'election',
            input_data: {
                'name' : '',
                'lock_date': '',
                'voting_date_start': '',
                'voting_date_end': '',
                'review_date_start': '',
                'review_date_end': '',
                //'no_of_candidates_per_list': '',
                'voting_for': '',
                'no_of_allowed_voting_list' : '',
                'no_of_allowed_voting_candidates' : ''
            },


            voting_for_select: [
                {
                    'key' : 'list',
                    'value': 'Lista'
                },
                {
                    'key' : 'candidate',
                    'value': 'Kandidat'
                },
                {
                    'key' : 'list_and_candidate',
                    'value': 'Lista i kandidat'
                }
            ]

        }
    },

    methods: {

        async getAdmin(){
            let vm = this
            await axios
                .get(process.env.VUE_APP_API_URL + "admin-auth" )
                .then(response => {
                vm.admin = response.data
            })
            .catch(() => {
                vm.admin = null
            });
        },

        async init(){
            let vm = this

            if ( vm.action === 'edit' ){

                await axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {

                        vm.input_data.name = response.data.name
                        vm.input_data.lock_date = response.data.lock_date
                        vm.input_data.voting_date_start = response.data.voting_date_start
                        vm.input_data.voting_date_end = response.data.voting_date_end
                        vm.input_data.review_date_start = response.data.review_date_start
                        vm.input_data.review_date_end = response.data.review_date_end
                        vm.input_data.review_date_end = response.data.review_date_end

                        // vm.input_data.no_of_candidates_per_list = response.data.settings
                        //     ? response.data.settings.no_of_candidates_per_list
                        //     : ''

                        vm.input_data.voting_for = response.data.settings
                            ? response.data.settings.voting_for
                            : ''

                        vm.input_data.no_of_allowed_voting_list = response.data.settings
                            ? response.data.settings.no_of_allowed_voting_list
                            : ''

                        vm.input_data.no_of_allowed_voting_candidates = response.data.settings
                            ? response.data.settings.no_of_allowed_voting_candidates
                            : ''



                        vm.$emit( 'returnData', response.data )
                    } )
                    .catch( error => { console.log( error ) } )
            }
            vm.setupForm()
        },


        getVotingForByID( id ){
            return this.voting_for_select.find( item => item.key === id )
        },


        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route
                }
            }

            let button_cancel2 = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + '/' + vm.id
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel2 )
                buttons.push( button_update)
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }



            let name_input = {
                name: 'name',
                type: 'input',
                title: 'Naziv',
                value: vm.input_data.name,
                width: 12,
                error: vm.errors['name'] ? vm.errors['name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 6,
                    input_width: 6
                }
            }


            let lock_date_input = {
                name: 'lock_date',
                type: 'datepicker',
                title: 'Datum i vrijeme zaključavanja',
                value: vm.input_data.lock_date,
                width: 12,
                error: vm.errors['lock_date'] ? vm.errors['lock_date'] : '',
                note: 'Nakon ovog datuma više se ne može editirati ništa vezano uz izbore',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                    title_width: 6,
                    input_width: 6
                }
            }

            let voting_date_start_input = {
                name: 'voting_date_start',
                type: 'datepicker',
                title: 'Datum i vrijeme početka glasanja',
                value: vm.input_data.voting_date_start,
                width: 12,
                error: vm.errors['voting_date_start'] ? vm.errors['voting_date_start'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                    title_width: 6,
                    input_width: 6
                }
            }

            let voting_date_end_input = {
                name: 'voting_date_end',
                type: 'datepicker',
                title: 'Datum i vrijeme završetka glasanja',
                value: vm.input_data.voting_date_end,
                width: 12,
                error: vm.errors['voting_date_end'] ? vm.errors['voting_date_end'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                    title_width: 6,
                    input_width: 6
                }
            }

            let review_date_start_input = {
                name: 'review_date_start',
                type: 'datepicker',
                title: 'Datum i vrijeme početka prebrojavanja',
                value: vm.input_data.review_date_start,
                width: 12,
                error: vm.errors['review_date_start'] ? vm.errors['review_date_start'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                    title_width: 6,
                    input_width: 6
                }
            }

            let review_date_end_input = {
                name: 'review_date_end',
                type: 'datepicker',
                title: 'Datum i vrijeme završetka prebrojavanja',
                value: vm.input_data.review_date_end,
                width: 12,
                error: vm.errors['review_date_end'] ? vm.errors['review_date_end'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                    title_width: 6,
                    input_width: 6
                }
            }


            // let no_of_candidates_per_list_input = {
            //     name: 'no_of_candidates_per_list',
            //     type: 'input',
            //     title: 'Max broj kandidata po listi',
            //     value: vm.input_data.no_of_candidates_per_list,
            //     width: 12,
            //     error: vm.errors['no_of_candidates_per_list'] ? vm.errors['no_of_candidates_per_list'] : '',
            //     note: '',
            //     explanation: '',
            //     placeholder: '',
            //     required: !vm.disabled,
            //     readonly: vm.disabled,
            //     disabled: vm.disabled,
            //     config: {
            //         title_width: 6,
            //         input_width: 6
            //     }
            // }



            let voting_for_input = {
                name: 'voting_for',
                type: 'select',
                title: 'Način glasanja',
                value: vm.input_data.voting_for ? vm.getVotingForByID( vm.input_data.voting_for ) : '',
                width: 12,
                error: vm.errors['voting_for'] ? vm.errors['voting_for'] : '',
                note: 'Što glasač može označiti na listiću',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.voting_for_select,
                    title_width: 6,
                    input_width: 6
                }
            }



            let no_of_allowed_voting_list_input = {
                name: 'no_of_allowed_voting_list',
                type: 'input',
                title: 'Maksimalan broj listi za odabir',
                value: vm.input_data.no_of_allowed_voting_list,
                width: 12,
                error: vm.errors['no_of_allowed_voting_list'] ? vm.errors['no_of_allowed_voting_list'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 6,
                    input_width: 6
                }
            }



            let no_of_allowed_voting_candidates_input = {
                name: 'no_of_allowed_voting_candidates',
                type: 'input',
                title: 'Maksimalan broj listi kandidata po listi',
                value: vm.input_data.no_of_allowed_voting_candidates,
                width: 12,
                error: vm.errors['no_of_allowed_voting_candidates'] ? vm.errors['no_of_allowed_voting_candidates'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 6,
                    input_width: 6
                }
            }



            let inputs = []

            inputs.push( name_input )
            inputs.push( lock_date_input )
            inputs.push( voting_date_start_input )
            inputs.push( voting_date_end_input )
            inputs.push( review_date_start_input )
            inputs.push( review_date_end_input )
            //inputs.push( no_of_candidates_per_list_input )
            inputs.push( voting_for_input )
            if( vm.input_data['voting_for'] === 'list' || vm.input_data['voting_for'] === 'list_and_candidate' )
                inputs.push( no_of_allowed_voting_list_input )
            if( vm.input_data['voting_for'] === 'candidate' || vm.input_data['voting_for'] === 'list_and_candidate' )
                inputs.push( no_of_allowed_voting_candidates_input )


            let settings = {
                width: 8,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
        *  on input return data
        */
        onInput( name, value ){

            let vm = this

            if( vm.input_data[name] === value )
                return;
            else
                vm.input_data[name] = value

            vm.setupForm()
        },

        /**
        *  on button click
        */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success)
                {
                    if( id == 'save' )
                    {
                        toastr.success( 'Uspješno spremljeno!' )
                        this.$router.push({ path: this.route } )
                    }
                    else if ( id == 'update' )
                    {
                        toastr.success( 'Uspješno ažurirano!' )
                        this.$router.push({ path: this.route + '/' + this.id } )
                    }

                }
                else
                {
                    if( data.messages.id )
                        toastr.error( data.messages.id )
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                if( data.success ){
                    toastr.error( 'Uspješno obrisano!' )
                    this.$router.push({ path: this.route } )
                } else {
                    if( data.messages.id )
                        toastr.error( data.messages.id )
                    else
                        toastr.error( 'Došlo je do greške' )
                }
            }

        },


    },
    mounted() {
        this.init()
    }
};
</script>

<style scoped>

</style>
