<template>

    <nav class="navbar-default navbar-static-side" role="navigation">
        <div class="sidebar-collapse">
            <ul class="nav metismenu" id="side-menu">
                <li class="nav-header">
                    <div class="dropdown profile-element">
                        <span class="block m-t-xs font-bold" style="color: white"> {{ app_name ? app_name : '' }}</span>
                        <span class="block m-t-xs font-bold"> {{ app_owner ? app_owner : '' }}</span>
                    </div>
                    <div class="logo-element">
                        {{ app_name ? app_name : '' }}
                    </div>
                </li>

                <li v-if="admin_permission" @click="change_active_link('election')" :class="{'active': active_link === 'election'}">
                    <router-link to="/election"><i class="fa fa-box"></i> <span class="nav-label">Izbori</span></router-link>
                </li>

                <li v-if="admin_permission && admin_permission !== 1 " @click="change_active_link('admin')" :class="{'active': active_link === 'admin'}">
                    <router-link to="/admin"><i class="fa fa-users"></i> <span class="nav-label">Administratori</span></router-link>
                </li>

            </ul>

        </div>
    </nav>



</template>

<script>

    import axios from "axios";

    export default {
        name: "Navigation",
        props:[
        ],
        data(){
            return {
                active_link: 'election',
                active_link_old: 'election',
                sec_level_menu: null,
                app_owner:process.env.VUE_APP_APP_OWNER,
                app_name:process.env.VUE_APP_APP_NAME,

                // user
                admin_permission: null
            }
        },
        methods:{

            async getAdmin(){
                let vm = this
                await axios.get( process.env.VUE_APP_API_URL + 'admin-auth' )
                    .then( response => [ vm.admin_permission = response.data.admin_permission_id ] )
                    .catch( error => { console.log( error ) } )
            },

            change_active_link( active_link, sec_level_menu = null ){
                this.sec_level_menu = sec_level_menu
                this.active_link_old = this.active_link;
                this.active_link = active_link
            },
            open_menu( sec_level_menu ){
                if( this.sec_level_menu === sec_level_menu ){
                    this.active_link = this.active_link_old
                    this.sec_level_menu = null
                } else {
                    this.active_link_old = this.active_link;
                    this.active_link = sec_level_menu
                    this.sec_level_menu = sec_level_menu
                }
            },


        },
        async mounted() {
            await this.getAdmin()
        }
    }
</script>

<style scoped>

</style>
