import { render, staticRenderFns } from "./CandidateLists.vue?vue&type=template&id=39815b48&scoped=true&"
import script from "./CandidateLists.vue?vue&type=script&lang=js&"
export * from "./CandidateLists.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39815b48",
  null
  
)

export default component.exports