<template>
    <div class="row">
        <label :class="title_width">
            <strong class="float-lg-right float-md-left">{{title}} {{ required ? '*' : ''}}</strong>
        </label>
        <div v-if="!readonly" :class="input_width">
            <span class="form-text m-b-none text-success">
                    {{ note }}
            </span>
            <div class="input-group-append">
                <multiselect
                        track-by="key"
                        label="value"
                        :placeholder="placeholder"
                        :options="options"
                        v-model="ret_value"
                        :searchable="true"
                        :allow-empty="!required"
                        :showLabels="false"
                        :multiple="config.multiple"
                        :close-on-select="!(config.multiple)"
                        :disabled="disabled"
                        @input="_return"
                >
                    <template slot="noOptions">Lista je prazna</template>
                    <template slot="noResult">Nema rezultata</template>
                </multiselect>
                <span
                        v-if="explanation"
                        class="input-group-addon"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="explanation"
                >
                    <i class="fa fa-info" aria-hidden="true"></i>
                </span>
            </div>
            <span class="form-text m-b-none text-danger">
                <div class="row">
                    <div class="col-lg-6">
                    {{ error[0] }}
                </div>
<!--                    <div class="col-md-6">-->
<!--                        <button class="btn float-right" style="margin-left: 2px; margin-right: 2px">123</button>-->
<!--                    </div>-->
                </div>
            </span>

        </div>
        <div v-else-if="config.multiple" :class="input_width">
            <input v-for="(item, index) in value"
                    :key="index"
                    :name="name"
                    :value="item.value"
                    :readonly="readonly"
                    :disabled="disabled"
                    class="form-control"
            >
        </div>
        <div v-else :class="input_width">
            <input
                    :name="name"
                    :value="value.value"
                    :readonly="readonly"
                    :disabled="disabled"
                    class="form-control"
            >
        </div>
    </div>
</template>

<script>

    //import axios from "axios";

    import Multiselect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css';


    export default {
        name: "SelectComponent",
        components:{ Multiselect },
        props: [
            'name',
            'value',
            'title',
            'error',
            'note',
            'explanation',
            'placeholder',
            'required',
            'readonly',
            'disabled',
            'config'
        ],
        data: function(){
            return {
                options: [],
                ret_value: null,
                title_width: ('col-form-label col-lg-' + ( this.config.title_width ? this.config.title_width : '4' ) ),
                input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '8' ) )
            }
        },

        methods: {
            _return(){
                if( this.config.multiple ){
                    let ret_array = [];
                    this.ret_value.forEach( function ( item ) {
                        ret_array.push( item.key )
                    } )
                    this.$emit( 'onInput', this.name, ret_array )
                } else {
                    this.$emit( 'onInput', this.name, ( this.ret_value ? ( this.ret_value.key ? this.ret_value.key : '' ) : '' ) )
                }
            }
        },

        mounted() {

            if( this.config && this.config.options ){
                if( typeof this.config.options === 'function' )
                    this.config.options.call( this )
                else
                    this.options = this.config.options
            }


            this.ret_value = this.value

            this._return()
        },
        watch: {
            value( value ){
                if( this.config.multiple ){
                    let ret_values_keys = []
                    this.ret_value.forEach( function( item ) {
                        ret_values_keys.push( item.key )
                    } )

                    let ret = false;

                    value.forEach( function( item ) {
                        if( !ret_values_keys.includes( item.key ) ){
                            ret = true
                        }
                    } )

                    ret_values_keys.forEach( function( _key ) {
                        if( !value.find( o => o.key === _key ) )
                            ret = true;
                    } )

                    if( ret ){
                        this.ret_value = value
                        this._return()
                    }

                } else {
                    if( value.key !== this.ret_value.key ){
                        this.ret_value = value
                        this._return()
                    }
                }

            },
            config: {
                deep: false,
                handler: function(){
                    if( this.config && this.config.options ){
                        if( this.config && this.config.options && typeof this.config.options === 'function' ){
                            this.options = []
                            this.config.options.call( this )
                        }
                        else
                            this.options = this.config.options
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
