<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Pipis birača</h5><br>
                <button
                    v-if="admin.admin_permission_id !== 1 "
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi birač
                </button>
                <button
                    v-if="admin.admin_permission_id !== 1 "
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="importData"
                >
                    Uvoz birača
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import Datatables from "@/components/datatable/Datatables";
import axios from "axios";
export default {
    name: "ElectionCommittees",
    components: { ElectionNavigation, Datatables },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: [],

            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'voter',
            datatables: {},

            election_committee_select: []
        }
    },

    methods:{

        rowClick( row ){
            if(this.admin && [2,3].includes( this.admin.admin_permission_id ))
                this.$router.push( { path: '/election/' + this.election_id + '/voter/' + row.id + '/edit' } )
        },

        create(){
            this.$router.push( { path: '/election/' + this.election_id + '/voter/create' } )
        },

        importData(){
            this.$router.push( { path: '/election/' + this.election_id + '/voter/import' } )
        },

        async fetchElectionCommittee(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        //'parent' : '0',
                        'election_id' : vm.election_id
                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.election_committee_select = []
                    response.data.data.forEach( item => {
                        vm.election_committee_select.push({
                            key: item.id,
                            value: item.constituency_name
                        })
                    })
                } )
                .catch( error => { console.log( error ) } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'election_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.election_id
                            },
                        },
                        {
                            'id': 'election_committee_id',
                            'title': 'Izborna jedinica',
                            'type': function( row ){
                                return row.election_committee.constituency_name || ' '
                            },
                            'filter': {
                                'type': 'select',
                                'search': vm.election_committee_select
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'group_name',
                            'title': 'Grupa',
                            'type': function( row ){
                                return row.group_name
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'access_to_voting',
                            'title': 'Pravo glasanja',
                            'type': function( row ){
                                return row.access_to_voting ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        'key': '1',
                                        'value': 'Da'
                                    },
                                    {
                                        'key': '0',
                                        'value': 'Ne'
                                    }
                                ]
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'voted',
                            'title': 'Glasao',
                            'type': function( row ){
                                return row.voted && row.voted.toString() === '1' ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        'key': '1',
                                        'value': 'Da'
                                    },
                                    {
                                        'key': '0',
                                        'value': 'Ne'
                                    }
                                ]
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'first_name',
                            'title': 'Ime',
                            'type': function( row ){
                                return row.first_name || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'last_name',
                            'title': 'Prezime',
                            'type': function( row ){
                                return row.last_name || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'type': function( row ){
                                return row.email || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'vat',
                            'title': 'OIB',
                            'type': function( row ){
                                return row.vat || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'academic_degree',
                            'title': 'Akademska titula',
                            'type': function( row ){
                                return row.academic_degree || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'professional_degree',
                            'title': 'Stručni stupanj',
                            'type': function( row ){
                                return row.professional_degree || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'authorization_number',
                            'title': 'Broj ovlaštenja',
                            'type': function( row ){
                                return row.authorization_number || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'status',
                            'title': 'Status',
                            'type': function( row ){
                                return row.status || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'region',
                            'title': 'Županija',
                            'type': function( row ){
                                return row.region || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'address',
                            'title': 'Adresa',
                            'type': function( row ){
                                return row.address || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'place_of_residence',
                            'title': 'Mjesto',
                            'type': function( row ){
                                return row.place_of_residence || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },
                        {
                            'id': 'gender',
                            'title': 'Spol',
                            'type': function( row ){
                                return row.gender || ' '
                            },
                            'column_visibility_group': {
                                id: 'voter',
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'voter' : 'Popis birača'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Popis Birača',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/election-committee',
                    name: 'Izborna povjerenstva'
                }
            ]
        }

    },

    async mounted() {
        await this.fetchElectionCommittee()
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
