<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <admin-election-committee-form
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    :election_id="election_id"
                    @returnData="setData"
                ></admin-election-committee-form>
            </div>
        </div>
    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import AdminElectionCommitteeForm from "@/views/pages/Election/AdminElectionCommittee/AdminElectionCommitteeForm";
export default {
    name: "AdminElectionCommitteeEdit",
    components: { AdminElectionCommitteeForm, ElectionNavigation },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            id: this.$route.params.admin_election_committee_id,
            breadcrumbs: [],
            name
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/admin-election-committee',
                    name: 'Članovi izbornog povjerenstva'
                },
                {
                    path: '/election/' + this.election_id + '/admin-election-committee/' + this.id + '/edit',
                    name: this.name
                }
            ]
        },

        setData( data ){
            this.name = data.first_name + ' ' + data.last_name
            this.setBreadcrumbs()
        }
    },
    mounted() {}
};
</script>

<style scoped>

</style>
