<template>
    <div>
        <breadcrumbs
            :title="'Izbori: ' + name"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Uredi izbore: {{ name }}</h5>
            </div>
            <div class="ibox-content">
                <election-form
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                ></election-form>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import ElectionForm from "@/views/pages/Election/ElectionForm";
export default {
    name: "ElectionEdit",
    components: { ElectionForm, Breadcrumbs },
    data(){
        return {
            id: this.$route.params.election_id,
            breadcrumbs: [],
            name: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/election',
                    name: 'Izbori'
                },
                {
                    path: ( '/election/' + this.id + '/edit' ),
                    name: this.name
                }
            ]
        },

        setData( data ){
            this.name = data.name
        }
    }
};
</script>

<style scoped>

</style>
