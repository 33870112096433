<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import Forms from "@/components/form/Forms";
import toastr from "toastr";
import axios from "axios";

export default {
    name: "AdminElectionCommitteeForm",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        election_id: {
            default: null
        }
    },
    data(){
        return {
            settings: {},
            errors:{},
            route: '/election/' + this.election_id + '/admin-election-committee',
            url: 'admin-election-committee',
            input_data:{},

            admin_select: [],

            election_committee_select: [],

            active_select: [
                {
                    'key': '1',
                    'value': 'Da'
                },
                {
                    'key' : '0',
                    'value': 'Ne'
                }
            ],

            admin_election_committee_parent_select: [],

            admin_election_committee_children_select: [],

            admin_election_committee_role_select: [],
        }
    },

    methods: {

        async init(){
            let vm = this

            if ( vm.action === 'edit' || vm.action === 'show' ){

                await axios.get( process.env.VUE_APP_API_URL + 'admin-election-committee/' + vm.id)
                    .then( response => {

                        vm.input_data['first_name'] = response.data.first_name
                        vm.input_data['last_name'] = response.data.last_name
                        vm.input_data['email'] = response.data.email
                        vm.input_data['vat'] = response.data.vat
                        vm.input_data['phone'] = response.data.phone
                        vm.input_data['authorization_number'] = response.data.authorization_number
                        vm.input_data['address'] = response.data.address
                        vm.input_data['place_of_residence'] = response.data.place_of_residence
                        vm.input_data['active'] = response.data.active === null ? '0' : response.data.active.toString()
                        vm.input_data['active_from'] = response.data.active_from
                        vm.input_data['active_to'] = response.data.active_to
                        vm.input_data['admin_id'] = response.data.admin_id
                        vm.input_data['election_committee_id'] = response.data.election_committee_id
                        vm.input_data['role_id'] = response.data.role_id

                        vm.input_data['parent_id'] = response.data.parent_id
                        vm.input_data['children_ids'] = []
                        if( response.data.children && response.data.children.length )
                            response.data.children.forEach( child => {
                                vm.input_data['children_ids'].push( child.id )
                            } )

                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( error => { console.log( error ) } )
            } else if ( vm.action === 'create' ) {
                vm.input_data = {}
                vm.setupForm()
            }
        },


        async fetchAdmin(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'admin', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        'admin_permission_id': '1'
                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.admin_select =
                        response.data.data.map( function( item ) {
                            return {
                                key: item.id,
                                value: item.first_name
                                    + ' '
                                    + item.last_name
                                    + ' '
                                    + item.email
                            }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        async fetchElectionCommittee(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        'election_id' : vm.election_id
                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.election_committee_select =
                        response.data.data.map( function( item ) {
                            return {
                                key: item.id,
                                value: item.name
                            }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        async fetchAdminElectionCommitteeParent(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'admin-election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        'election_id' : vm.election_id
                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.admin_election_committee_parent_select =
                        response.data.data.map( function( item ) {
                            return {
                                key: item.id,
                                value: item.first_name + ' ' + item.last_name
                            }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },


        async fetchAdminElectionCommitteeChildren(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'admin-election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        'parent' : '1',
                        'election_id' : vm.election_id
                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.admin_election_committee_children_select =
                        response.data.data.map( function( item ) {
                            return {
                                key: item.id,
                                value: item.first_name + ' ' + item.last_name
                            }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        async fetchAdminElectionCommitteeRole(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'admin-election-committee-role', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {},
                    options: {},
                }

            } )
                .then( response => {
                    vm.admin_election_committee_role_select =
                        response.data.data.map( function( item ) {
                            return {
                                key: item.id,
                                value: item.name
                            }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        getAdminByID( id ){
            return this.admin_select.find( item => item.key === id )
        },

        getElectionCommitteeByID( id ){
            return this.election_committee_select.find( item => item.key === id )
        },

        getActiveByID( id ){
            return this.active_select.find( item => item.key === id )
        },

        getAdminElectionCommitteeParentByID( id ){
            return this.admin_election_committee_parent_select.find( item => item.key === id )
        },

        getAdminElectionCommitteeChildrenByID( ids ){
            let ret = []
            ids.forEach( id => {
                ret.push( this.admin_election_committee_children_select.find( item => item.key === id ) )
            } )
            return ret
        },

        getAdminElectionCommitteeRoleByID( id ){
            return this.admin_election_committee_role_select.find( item => item.key === id )
        },


        setupForm(){
            let vm = this

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }


            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_delete )
                buttons.push( button_update)
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = []




            let election_id_input = {
                name: 'election_id',
                type: 'hidden',
                title: 'Naziv',
                value: vm.election_id,
                width: 12,
                error: vm.errors['election_id'] ? vm.errors['election_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( election_id_input )




            let first_name_input = {
                name: 'first_name',
                type: 'input',
                title: 'Ime',
                value: vm.input_data.first_name,
                width: 12,
                error: vm.errors['first_name'] ? vm.errors['first_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( first_name_input )




            let last_name_input = {
                name: 'last_name',
                type: 'input',
                title: 'Prezime',
                value: vm.input_data.last_name,
                width: 12,
                error: vm.errors['last_name'] ? vm.errors['last_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( last_name_input )




            let vat_input = {
                name: 'vat',
                type: 'input',
                title: 'OIB',
                value: vm.input_data.vat,
                width: 12,
                error: vm.errors['vat'] ? vm.errors['vat'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( vat_input )




            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value: vm.input_data.email,
                width: 12,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( email_input )





            let phone_input = {
                name: 'phone',
                type: 'input',
                title: 'Mobitel',
                value: vm.input_data.phone,
                width: 12,
                error: vm.errors['phone'] ? vm.errors['phone'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( phone_input )



            let authorization_number_input = {
                name: 'authorization_number',
                type: 'input',
                title: 'Broj ovlaštenja',
                value: vm.input_data.authorization_number,
                width: 12,
                error: vm.errors['authorization_number'] ? vm.errors['authorization_number'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( authorization_number_input )




            let address_input = {
                name: 'address',
                type: 'input',
                title: 'Adresa',
                value: vm.input_data.address,
                width: 12,
                error: vm.errors['address'] ? vm.errors['address'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( address_input )




            let place_of_residence_input = {
                name: 'place_of_residence',
                type: 'input',
                title: 'Mjesto',
                value: vm.input_data.place_of_residence,
                width: 12,
                error: vm.errors['place_of_residence'] ? vm.errors['place_of_residence'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( place_of_residence_input )




            let active_input = {
                name: 'active',
                type: 'select',
                title: 'Aktivan',
                value: vm.input_data.active ? vm.getActiveByID( vm.input_data.active ) : '',
                width: 12,
                error: vm.errors['active'] ? vm.errors['active'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disable,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.active_select,
                }
            }
            inputs.push( active_input )




            let active_from_input = {
                name: 'active_from',
                type: 'datepicker',
                title: 'Aktivan od',
                value: vm.input_data.active_from,
                width: 12,
                error: vm.errors['active_from'] ? vm.errors['active_from'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                }
            }
            if( vm.input_data.active && vm.input_data.active === '1' )
                inputs.push( active_from_input )




            let active_to_input = {
                name: 'active_to',
                type: 'datepicker',
                title: 'Aktivan do',
                value: vm.input_data.active_to,
                width: 12,
                error: vm.errors['active_to'] ? vm.errors['active_to'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                }
            }
            if( vm.input_data.active && vm.input_data.active === '1' )
                inputs.push( active_to_input )




            let admin_id_input = {
                name: 'admin_id',
                type: 'select',
                title: 'Račun za pristup',
                value: vm.input_data.admin_id ? vm.getAdminByID( vm.input_data.admin_id ) : '',
                width: 12,
                error: vm.errors['admin_id'] ? vm.errors['admin_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.admin_select,
                }
            }

            if( this.input_data.active && this.input_data.active === '1' )
                inputs.push( admin_id_input )




            let election_committee_id_input = {
                name: 'election_committee_id',
                type: 'select',
                title: 'Izborno povjerenstvo',
                value: vm.input_data.election_committee_id ? vm.getElectionCommitteeByID( vm.input_data.election_committee_id ) : '',
                width: 12,
                error: vm.errors['election_committee_id'] ? vm.errors['election_committee_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.election_committee_select,
                }
            }
            inputs.push( election_committee_id_input )




            let role_id_input = {
                name: 'role_id',
                type: 'select',
                title: 'Funkcija',
                value: vm.input_data.role_id ? vm.getAdminElectionCommitteeRoleByID( vm.input_data.role_id ) : '',
                width: 12,
                error: vm.errors['role_id'] ? vm.errors['role_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.admin_election_committee_role_select,
                }
            }
            inputs.push( role_id_input )





            let parent_id_input = {
                name: 'parent_id',
                type: 'select',
                title: 'Član je zamjenik za',
                value: vm.input_data.parent_id ? vm.getAdminElectionCommitteeParentByID( vm.input_data.parent_id ) : '',
                width: 12,
                error: vm.errors['parent_id'] ? vm.errors['parent_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.admin_election_committee_parent_select,
                }
            }
            inputs.push( parent_id_input )




            let children_ids_input = {
                name: 'children_ids',
                type: 'select',
                title: 'Člana mijenjaju',
                value: vm.input_data.children_ids ? vm.getAdminElectionCommitteeChildrenByID( vm.input_data.children_ids ) : [],
                width: 12,
                error: vm.errors['children_ids'] ? vm.errors['children_ids'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.admin_election_committee_children_select,
                    multiple: true
                }
            }
            inputs.push( children_ids_input )




            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            if( this.input_data[ name ] !== value ){
                this.input_data[ name ] = value
                this.setupForm()
            }
        },


        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route  } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    async mounted() {
        await this.fetchAdmin()
        await this.fetchElectionCommittee()
        await this.fetchAdminElectionCommitteeChildren()
        await this.fetchAdminElectionCommitteeParent()
        await this.fetchAdminElectionCommitteeRole()
        await this.init()
    }
};
</script>

<style scoped>

</style>
