<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Kandidacijske liste</h5><br>
                <button
                    v-if="admin.admin_permission_id !== 1 "
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Nova kandidacijska lista
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import Datatables from "@/components/datatable/Datatables";
export default {
    name: "CandidateLists",
    components: { ElectionNavigation, Datatables },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: [],

            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'candidate-list',
            datatables: {},
        }
    },

    methods:{

        rowClick( row ){
            if(this.admin && [2,3].includes( this.admin.admin_permission_id ))
                this.$router.push( { path: '/election/' + this.election_id + '/candidate-list/' + row.id + '/edit' } )
        },

        create(){
            this.$router.push( { path: '/election/' + this.election_id + '/candidate-list/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'election_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.election_id
                            },
                        },
                        {
                            'id': 'election_committee_name',
                            'title': 'Izborna jedinica',
                            'type': function( row ){
                                return row.ballot.election_committee.constituency_name || ''
                            },
                            'column_visibility_group': {
                                id: 'candidate_list',
                            }
                        },
                        {
                            'id': 'no',
                            'title': 'Broj',
                            'type': function( row ){
                                return row.no
                            },
                            'column_visibility_group': {
                                id: 'candidate_list',
                            }
                        },
                        {
                            'id': 'name',
                            'title': 'Naziv',
                            'type': function( row ){
                                return row.name
                            },
                            'column_visibility_group': {
                                id: 'candidate_list',
                            }
                        },
                        {
                            'id': 'candidates',
                            'title': 'Kandidati',
                            'searchable' : false,
                            'type': function( row ){
                                let ret = ''
                                row.candidates.forEach( ( item, index ) => {
                                    ret += ( (index === 0) ? '' : ', ' )
                                        + item.no
                                        + '. '
                                        + item.name
                                } )
                                return ret
                            },
                            'column_visibility_group': {
                                id: 'candidate_list',
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'candidate_list' : 'Kandidacijske liste'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Kandidacijske liste',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/candidate-list',
                    name: 'Kandidacijske liste'
                }
            ]
        }

    },

    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
