<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>

        <election-navigation
            :id="id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h1>{{election.name}}</h1>

                <button
                    v-if="admin && [2,3].includes( admin.admin_permission_id )"
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="edit"
                >
                    Uredi izbore
                </button>

            </div>

            <div v-if="election" class="ibox-content">
                <div class="row">
                    <div class="col-md-6">
                        <table class="table text-dark text-xl-left">
                            <tbody>
                            <tr>
                                <th>Datum i vrijeme zaključavanja:</th>
                                <td>{{ election.lock_date }}</td>
                            </tr>
                            <tr>
                                <th>Datum i vrijeme početka glasanja:</th>
                                <td>{{ election.voting_date_start }}</td>
                            </tr>
                            <tr>
                                <th>Datum i vrijeme završetka glasanja:</th>
                                <td>{{ election.voting_date_end }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-md-6">
                        <table class="table text-dark text-xl-left">
                            <tbody>
                            <tr>
                                <th>Datum i vrijeme početka prebrojavanja listića:</th>
                                <td>{{ election.review_date_start }}</td>
                            </tr>
                            <tr>
                                <th>Datum i vrijeme završetka prebrojavanja listića:</th>
                                <td>{{ election.review_date_end }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>


        <div v-if="election">
            <div v-for="election_committee in election.election_committees" :key="election_committee.id" class="ibox">
                <div class="ibox-title">
                    <h3>
                        Izborno povjerenstvo:
                        {{ election_committee.name }}
                        {{ election_committee.constituency ? ( '; Izborna jedinica: ' + election_committee.constituency_name) : '' }}
                    </h3>
                </div>

                <div class="ibox-content">

                    <div class="row">
                        <div class="col-md-12">
                            <h3> </h3>
                            <div class="">
                                <div class="row">

                                    <div class="col-lg-4" style="background-color: white">
                                        <h4>Izlaznost birača</h4>
                                        <ul class="stat-list">
                                            <li>
                                                <h5 class="no-margins">
                                                    Glasalo: {{ election_committee.vote_response.voters_voted_count }} birača, od: {{ election_committee.vote_response.voters_count }} ukupno
                                                </h5>
                                                <small></small>
                                                <div class="stat-percent" style="margin-left: 10px">
                                                    <h5>
                                                    {{
                                                        (
                                                            election_committee.vote_response.voters_voted_count / ( election_committee.vote_response.voters_count || 1 ) * 100
                                                        ).toFixed(2) + '%'
                                                    }}
                                                    </h5>
                                                </div>
                                                <div class="progress " style="margin-top: 5px">
                                                    <div
                                                        :style="{
                                                            width: (
                                                                election_committee.vote_response.voters_voted_count / (election_committee.vote_response.voters_count || 1 ) * 100
                                                            ).toFixed(2) + '%',
                                                            height: '40px'
                                                        }"
                                                        class="progress-bar"
                                                    >
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>

                                    <div v-if="election_committee.constituency" class="col-lg-1"></div>

                                    <div
                                        v-if="election_committee.constituency"
                                        @click="downloadReportByRegion( election, election_committee )"
                                        style="cursor: pointer"
                                        class="col-lg-3">
                                        <div class="widget style1 navy-bg">
                                            <div class="row">
                                                <div class="col-8 text-right">
                                                    <span> <strong>Izlaznost biraća po županijama</strong> </span>
                                                    <h2 class="font-bold">Preuzmi</h2>
                                                </div>
                                                <div class="col-4">
                                                    <i class="fa fa-download fa-3x"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="election_committee.constituency" class="col-lg-1"></div>

                                    <div
                                        v-if="election_committee.constituency"
                                        @click="downloadReportByPlaceOfResidence( election, election_committee )"
                                        class="col-lg-3"
                                        style="cursor: pointer"
                                    >
                                        <div class="widget style1 navy-bg">
                                            <div class="row">
                                                <div class="col-8 text-right">
                                                    <span> <strong>Izlaznost biraća po mjestu stanovanja</strong> </span>
                                                    <h2 class="font-bold">Preuzmi</h2>
                                                </div>
                                                <div class="col-4">
                                                    <i class="fa fa-download fa-3x"></i>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div

                                        v-if="election_committee.constituency && election.results"
                                        class="col-lg-4"
                                    >
                                        <div class="widget style1 red-bg">
                                            <div class="row">
                                                <div class="col-6 text-right">
                                                    <span> <strong></strong> </span>
                                                    <h2 class="font-bold">Ispis glasačkih listića</h2>
                                                </div>
                                                <div class="col-6 text-center">
                                                    <i class="fa fa-cloud-download-alt fa-5x"></i>
                                                </div>
                                            </div>

                                                <form v-if="show_ballot_inputs" role="form" style="color: black">
                                                    <div class="form-group">
                                                        <label>Od</label>
                                                        <input
                                                            v-model="ballot_inputs[election_committee.id]['from']"
                                                            type="text"
                                                            placeholder="Od"
                                                            class="form-control"
                                                            style="max-width: 250px"
                                                        >
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Do</label>
                                                        <input
                                                            v-model="ballot_inputs[election_committee.id]['to']"
                                                            type="text"
                                                            placeholder="Do"
                                                            class="form-control"
                                                            style="max-width: 250px"
                                                        >
                                                    </div>
                                                    <div>
                                                        <button
                                                            @click.prevent="downloadBallots( election, election_committee, ballot_inputs[election_committee.id]['from'], ballot_inputs[election_committee.id]['to'] )"
                                                            class="btn btn-sm btn-primary float-right m-t-n-xs"
                                                        >
                                                            <strong>Preuzmi</strong>
                                                        </button>
                                                    </div>
                                                </form>
                                            <br>

                                        </div>

                                    </div>

                                </div>


                                <div class="hr-line-dashed"></div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <h4>Članovi izbornog povjerenstva</h4>
                                        <table class="table text-dark text-xl-left">
                                            <thead>

                                            <td></td>
                                            <td><strong>Član</strong></td>
                                            <td><strong>Aktivan</strong></td>
                                            </thead>
                                            <tbody>
                                            <tr v-for="admin_election_committee in election_committee.admin_election_committee" :key="admin_election_committee.id" >
                                                <td> {{ admin_election_committee.role.name }}</td>
                                                <td>{{ admin_election_committee.first_name + ' ' + admin_election_committee.last_name }}</td>
                                                <td>
                                                    {{
                                                        ( admin_election_committee.active ? 'Da ' : 'Ne ' )
                                                        + ( admin_election_committee.active_from || admin_election_committee.active_to ? '( ' : '' )
                                                        + ( admin_election_committee.active_from ? admin_election_committee.active_from : '' )
                                                        + ( admin_election_committee.active_from || admin_election_committee.active_to ? ' - ' : '' )
                                                        + ( admin_election_committee.active_to ? admin_election_committee.active_to : '' )
                                                        + ( admin_election_committee.active_from || admin_election_committee.active_to ? ' )' : '' )
                                                    }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="election_committee.ballot" class="col-md-6">
                                        <h4>Kandidacijske liste</h4>
                                        <ul class="list-group">
                                            <li v-for="candidate_list in election_committee.ballot.candidate_lists" :key="candidate_list.id" class="list-group-item">
                                                <h5>{{ candidate_list.no + '. ' + candidate_list.name }}</h5>
                                                <ul class="list-group"  style="margin-left: 30px">
                                                    <li v-for="candidate in candidate_list.candidates" :key="candidate.id" class="list-group-item">
                                                        <div style="margin-left: 30px">
                                                            {{ candidate.no + '. ' + candidate.name }}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import toastr from "toastr";
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    name: "ElectionDetails",
    components: {ElectionNavigation, Loading },
    props:['admin'],
    data(){
        return {
            id: this.$route.params.election_id,
            breadcrumbs: [],
            election: {},
            open_loader: false,
            ballot_inputs: {},
            show_ballot_inputs: false,
        }
    },
    methods:{
        async fetchElection(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'election/' + vm.id, {
                page: 1,
                per_page: -1,
            } )
                .then( function ( response ) {
                    vm.election = response.data
                } )
                .catch( error => console.log( error ) )
        },

        setupBallotsInputs(){
            this.election.election_committees.forEach( item => {
                this.ballot_inputs[ item.id ] = {
                    from: null,
                    to: null,
                }
            } )
            this.show_ballot_inputs = true
        },

        edit(){
            this.$router.push( { path: '/election/' + this.id + '/edit' } )
        },


        downloadReportByRegion( election, election_committee ){
            let vm = this
            vm.open_loader = true
            axios({
                    method: 'GET',
                    url: process.env.VUE_APP_API_URL + 'election/download-report-by-region/' + election.id + '/' + election_committee.id,
                    data: {},
                    responseType: 'blob'
                }
            ).then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]),
                    {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                );
                link.setAttribute('download', election.name + ' - ' + election_committee.name + '.xlsx');
                document.body.appendChild(link);
                link.click();
                vm.open_loader = false
            }, (error) => {
                vm.open_loader = false
                console.log(error);
                toastr.error( error.toString() )
            });
        },

        downloadReportByPlaceOfResidence( election, election_committee ){
            let vm = this
            vm.open_loader = true
            axios({
                    method: 'GET',
                    url: process.env.VUE_APP_API_URL + 'election/download-report-by-place-of-residence/' + election.id + '/' + election_committee.id,
                    data: {},
                    responseType: 'blob'
                }
            ).then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]),
                    {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                );
                link.setAttribute('download', election.name + ' - ' + election_committee.name + '.xlsx');
                document.body.appendChild(link);
                link.click();
                vm.open_loader = false
            }, (error) => {
                vm.open_loader = false
                console.log(error);
                toastr.error( error.toString() )
            });
        },

        downloadBallots( election, election_committee, from, to ){
            let vm = this
            vm.open_loader = true
            axios( {
                method: 'GET',
                url: process.env.VUE_APP_API_URL + 'election/download-ballots/' + election.id + '/' + election_committee.id,
                params:{
                    from,
                    to
                },
                responseType: 'blob',
            }).then( ( response )=> {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', election.name + ' - ' + election_committee.name + '.pdf'  )
                document.body.appendChild(link)
                link.click()
                vm.open_loader = false
            }, ( error )=> {
                vm.open_loader = false
                console.log(error);
            })
        }

    },


    async mounted() {
        await this.fetchElection()
        this.setupBallotsInputs()
    }
};
</script>

<style scoped>

</style>
