<template>
    <div class="container">
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>

        <div class="row" style="margin-top: 10%">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">Prijava</div>

                    <div class="panel-body">
                        <div class="col-md-12" style="text-align: center">
                            <img src="@/img/app/logo.png" style="align-items: center">
                        </div>

                        <form class="form-horizontal">


                            <div :class="{'form-group': true}">
                                <label
                                    for="password"
                                    class="col-md-4 control-label"
                                >
                                    Lozinka
                                </label>

                                <div class="col-md-12">
                                    <input
                                        id="password"
                                        type="password"
                                        class="form-control"
                                        name="password"
                                        v-model="password"
                                        required
                                    >

                                    <span
                                        v-if="errors && errors.password"
                                        class="help-block"
                                    >
                                        <strong>
                                            {{errors.password[0] }}
                                        </strong>
                                    </span>

                                </div>
                            </div>

                            <div :class="{'form-group': true}">
                                <label
                                    for="password"
                                    class="col-md-4 control-label"
                                >
                                    Ponovi lozinku
                                </label>

                                <div class="col-md-12">
                                    <input
                                        id="password_confirm"
                                        type="password"
                                        class="form-control"
                                        name="password_confirm"
                                        v-model="password_confirm"
                                        required
                                    >

                                    <span
                                        v-if="errors && errors.password_confirm"
                                        class="help-block"
                                    >
                                        <strong>
                                            {{errors.password_confirm[0] }}
                                        </strong>
                                    </span>

                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-8 col-md-offset-4">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        @click.prevent="reset"
                                    >
                                        Pošalji
                                    </button>
                                    <!--<a class="btn btn-link" @click.prevent="forgotPassword">
                                        Forgot Your Password?
                                    </a>-->

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "Login",
    components: { Loading },
    data: function() {
        return {
            password: null,
            password_confirm: null,
            errors: null,
            open_loader: false,
        }
    },

    methods:{

        reset(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "reset-password", {
                password: vm.password,
                password_confirm: vm.password_confirm,
                token: this.$route.params.token
            })
                .then(response => {
                    if( response.data.success ){
                        vm.$router.push('/login')
                    } else {
                        vm.errors = response.data.messages
                    }
                    vm.open_loader = false
                })
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                    vm.open_loader = false
                } )
        }

    },

    mounted(){

    },


}
</script>

<style scoped>

</style>
