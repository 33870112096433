<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                {{ name }}
            </div>
        </div>
        <div class="ibox-content">
            <election-committee-form
                :disabled="false"
                :id="id"
                :modal="false"
                :action="'edit'"
                :election_id="election_id"
                @returnData="setData"
            ></election-committee-form>
        </div>

    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import ElectionCommitteeForm from "@/views/pages/Election/ElectionCommittee/ElectionCommitteeForm";
export default {
    name: "ElectionCommitteeEdit",
    components: { ElectionNavigation, ElectionCommitteeForm },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            id: this.$route.params.election_committee_id,
            breadcrumbs: [],
            name
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/election-committee',
                    name: 'Izborna povjerenstva'
                },
                {
                    path: '/election/' + this.election_id + '/election-committee/ + this.id' + '/edit',
                    name: this.name
                }
            ]
        },

        setData( data ){
            this.name = data.name
            this.setBreadcrumbs()
        }
    },
    mounted() {}
};
</script>

<style scoped>

</style>
