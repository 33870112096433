<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Izborna povjerenstva</h5><br>
                <button
                    v-if="admin.admin_permission_id !== 1 "
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novo izborno povjerenstvo
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import Datatables from "@/components/datatable/Datatables";
export default {
    name: "ElectionCommittees",
    components: { ElectionNavigation, Datatables },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: [],

            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'election-committee',
            datatables: {},
        }
    },

    methods:{

        rowClick( row ){
            if(this.admin && [2,3].includes( this.admin.admin_permission_id ))
                this.$router.push( { path: '/election/' + this.election_id + '/election-committee/' + row.id + '/edit' } )
        },

        create(){
            this.$router.push( { path: '/election/' + this.election_id + '/election-committee/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'election_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.election_id
                            },
                        },
                        {
                            'id': 'name',
                            'title': 'Izborno povjrenestvo',
                            'type': function( row ){
                                return row.name
                            },
                            'column_visibility_group': {
                                id: 'election_committee',
                            }
                        },
                        {
                            'id': 'constituency_name',
                            'title': 'Izborna jedinica',
                            'type': function( row ){
                                return row.constituency ? row.constituency_name : ''
                            },
                            'column_visibility_group': {
                                id: 'election_committee',
                            }
                        },
                        {
                            'id': 'parent',
                            'title': 'Nadležno izborno povjerenstvo',
                            'searchable': false,
                            'type': function( row ){
                                return row.parent ? row.parent.name : ''
                            },
                            'column_visibility_group': {
                                id: 'election_committee',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'election_committee' : 'Izborna povjerenstva'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Izborna povjerenstva',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/election-committee',
                    name: 'Izborna povjerenstva'
                }
            ]
        }

    },

    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
