var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"title":'Izbori',"breadcrumbs":_vm.allBreadcrumbs}}),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light",staticStyle:{"background-color":"#2F4050"}},[_c('a',{class:{
                'navbar-brand btn btn-light btn-xl': true,
                'active': _vm.active_route === _vm.route
            },on:{"click":function($event){return _vm.open(_vm.route)}}},[_c('strong',[_vm._v("Izbori")])]),_c('ul',{staticClass:"navbar-nav mr-auto"},[(_vm.admin.admin_permission_id !== 1)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                        'nav-link btn btn-light': true,
                        'active': _vm.active_route === _vm.route + '/election-committee'
                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/election-committee' )}}},[_c('strong',[_vm._v("Izborna povjerenstva")])])]):_vm._e(),(_vm.admin.admin_permission_id !== 1 )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                        'nav-link btn btn-light': true,
                        'active': _vm.active_route === _vm.route + '/admin-election-committee'
                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/admin-election-committee' )}}},[_c('strong',[_vm._v("Članovi izbornih povjerenstava")])])]):_vm._e(),(_vm.admin.admin_permission_id !== 1 )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                        'nav-link btn btn-light': true,
                        'active': _vm.active_route === _vm.route + '/candidate-list'
                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/candidate-list' )}}},[_c('strong',[_vm._v("Kandidacijse liste")])])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                        'nav-link btn btn-light': true,
                        'active': _vm.active_route === _vm.route + '/voter'
                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/voter' )}}},[_c('strong',[_vm._v("Popis birača")])])]),(_vm.admin.admin_permission_id !== 1)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                        'nav-link btn btn-light': true,
                        'active': _vm.active_route === _vm.route + '/emailing'
                    },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/emailing' )}}},[_c('strong',[_vm._v("Emailing")])])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }