<template>
<div>
    <breadcrumbs
        :title="'Izbori '"
        :breadcrumbs="breadcrumbs"
    ></breadcrumbs>
    <div class="ibox">
        <div class="ibox-title">
            <h5>Novi izbori</h5>
        </div>
        <div class="ibox-content">
            <election-form
                :disabled="false"
                :id="null"
                :modal="false"
                :action="'create'"
            ></election-form>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import ElectionForm from "@/views/pages/Election/ElectionForm";

export default {
    name: "ElectionCreate",
    components: {
        ElectionForm,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election',
                    name: 'Izbori'
                },
                {
                    path: '/election/create',
                    name: 'Novi izbori'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
