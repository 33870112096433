<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import Forms from "../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";

export default {
    name: "AdminForm",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/admin',
            url: 'admin',
            input_data: {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                password: '',
                password_confirm: '',

                valid_from: '',
                valid_to: '',

                admin_permission_id: '',
            },

            admin: null,

            admin_permission_select: [],


        }
    },

    methods: {

        async getAdmin(){
            let vm = this
            await axios
                .get(process.env.VUE_APP_API_URL + "admin-auth" )
                .then(response => {
                    vm.admin = response.data
                })
                .catch(() => {
                    vm.admin = null
                });
        },


        async fetchAdminPermissions(  ){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'admin-permission', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {},
                    options: {},
                }

            } )
                .then( response => {
                    vm.admin_permission_select =
                        response.data.data.map( function( item ) {
                            return { key: item.id.toString(), value: item.name }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        getAdminPermissionByID( id ){
            return this.admin_permission_select.find( item => item.key === id )
        },


        async init(){
            let vm = this

            if( !vm.admin ) return

            if ( vm.action === 'edit' ){

                await axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {

                        vm.input_data.first_name = response.data.first_name
                        vm.input_data.last_name = response.data.last_name
                        vm.input_data.username = response.data.username
                        vm.input_data.email = response.data.email
                        vm.input_data.password = ''
                        vm.input_data.password_confirm = ''
                        vm.input_data.valid_from = response.data.valid_from
                        vm.input_data.valid_to = response.data.valid_to

                        vm.input_data.admin_permission_id = response.data.admin_permission_id.toString()


                        vm.$emit( 'returnData', response.data )
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )


            }


            vm.setupForm()
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_reset = {
                id: 'forgot_password',
                title: 'Pošalji poveznicu za pristup',
                type: 'success',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url + '/forgot-password'
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_delete )
                buttons.push( button_update)
                buttons.push( button_reset )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }


            let admin_permission_id_input = {
                name: 'admin_permission_id',
                type: 'select',
                title: 'Tip korisnika',
                value: vm.input_data.admin_permission_id ? vm.getAdminPermissionByID( vm.input_data.admin_permission_id ) : '',
                width: 12,
                error: vm.errors['admin_permission_id'] ? vm.errors['admin_permission_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.admin_permission_select,
                }
            }

            let first_name_input = {
                name: 'first_name',
                type: 'input',
                title: 'Ime',
                value: vm.input_data.first_name,
                width: 12,
                error: vm.errors['first_name'] ? vm.errors['first_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }

            let last_name_input = {
                name: 'last_name',
                type: 'input',
                title: 'Prezime',
                value: vm.input_data.last_name,
                width: 12,
                error: vm.errors['last_name'] ? vm.errors['last_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }

            let username_input = {
                name: 'username',
                type: 'input',
                title: 'Korisničko ime',
                value: vm.input_data.username,
                width: 12,
                error: vm.errors['username'] ? vm.errors['username'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }

            let email_input = {
                name: 'email',
                type: 'input',
                title: 'E-mail',
                value: vm.input_data.email,
                width: 12,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    'email': true
                }
            }

            let password_input = {
                name: 'password',
                type: 'input',
                title: 'Lozinka',
                value: vm.input_data.password,
                width: 12,
                error: vm.errors['password'] ? vm.errors['password'] : '',
                note: vm.action === 'edit' ? 'Ako ne želite mijenjati password ostavite prazno' : '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    'password': true
                }
            }

            let password_confirm_input = {
                name: 'password_confirm',
                type: 'input',
                title: 'Ponovi lozinku',
                value: vm.input_data.password_confirm,
                width: 12,
                error: vm.errors['password_confirm'] ? vm.errors['password_confirm'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    'password': true
                }
            }

            let valid_from_input = {
                name: 'valid_from',
                type: 'datepicker',
                title: 'Vrijedi od',
                value: vm.input_data.valid_from,
                width: 12,
                error: vm.errors['valid_from'] ? vm.errors['valid_from'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                }
            }

            let valid_to_input = {
                name: 'valid_to',
                type: 'datepicker',
                title: 'Vrijedi do',
                value: vm.input_data.valid_to,
                width: 12,
                error: vm.errors['valid_to'] ? vm.errors['valid_to'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'datetime',
                }
            }


            let inputs = []
            inputs.push( admin_permission_id_input )

            if( vm.input_data.admin_permission_id === '1' ){
                inputs.push( first_name_input )
                inputs.push( last_name_input )
                inputs.push( email_input ) // for users username===email
                inputs.push( valid_from_input )
                inputs.push( valid_to_input )
            } else if ( vm.input_data.admin_permission_id === '2' ){
                inputs.push( first_name_input )
                inputs.push( last_name_input )
                inputs.push( username_input )
                inputs.push( email_input )
                inputs.push( password_input )
                inputs.push( password_confirm_input )
            } else if ( vm.input_data.admin_permission_id === '3' ) {
                inputs.push(first_name_input)
                inputs.push(last_name_input)
                inputs.push(username_input)
                inputs.push(email_input)
                inputs.push(password_input)
                inputs.push(password_confirm_input)
            }


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        async onInput( name, value ){
            let vm = this

            if( vm.input_data[name] === value )
                return;
            else
                vm.input_data[name] = value


            vm.setupForm()

        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            let vm = this
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id === 'save' ){
                        toastr.success( 'Uspješno spremljeno!' )
                        this.$router.push({ path: this.route + '/' + data.data.id + '/edit' } )
                    }
                    else if ( id === 'update' ){
                        toastr.success( 'Uspješno ažurirano!' )
                    }
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id === 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: vm.route } )
            } else if ( action === 'forgot_password' ){
                if( data.success ){
                    toastr.success( 'Uspješno poslano!' )
                } else {
                    console.log( data )
                }
            }
        },


    },
    async mounted() {
        let vm = this
        await vm.getAdmin()
        await vm.fetchAdminPermissions()
        await this.init()
    }
};
</script>

<style scoped>

</style>
