import Vue from "vue";
import VueRouter from "vue-router";



import Login from "../views/auth/Login.vue";
import ResetPassword from "@/views/auth/ResetPassword";

import Logout from "@/views/auth/Logout";

import Home from "../views/layout/Home";

import Dashboard from "../views/pages/Dashboard";
import Admins from "../views/pages/Admin/Admins";
import AdminEdit from "../views/pages/Admin/AdminEdit";

import AdminCreate from "../views/pages/Admin/AdminCreate";
import Elections from "@/views/pages/Election/Elections";
import ElectionDetails from "@/views/pages/Election/ElectionDetails";
import ElectionCreate from "@/views/pages/Election/ElectionCreate";
import ElectionEdit from "@/views/pages/Election/ElectionEdit";

import ElectionBase from "@/views/pages/Election/ElectionBase";
import ElectionCommittees from "@/views/pages/Election/ElectionCommittee/ElectionCommittees";
import ElectionCommitteeCreate from "@/views/pages/Election/ElectionCommittee/ElectionCommitteeCreate";

import ElectionCommitteeEdit from "@/views/pages/Election/ElectionCommittee/ElectionCommitteeEdit";
import AdminsElectionCommittees from "@/views/pages/Election/AdminElectionCommittee/AdminsElectionCommittees";
import AdminElectionCommitteeCreate from "@/views/pages/Election/AdminElectionCommittee/AdminElectionCommitteeCreate";

import AdminElectionCommitteeEdit from "@/views/pages/Election/AdminElectionCommittee/AdminElectionCommitteeEdit";
import CandidateLists from "@/views/pages/Election/CandidateList/CandidateLists";
import CandidateListCreate from "@/views/pages/Election/CandidateList/CandidateListCreate";

import CandidateListEdit from "@/views/pages/Election/CandidateList/CandidateListEdit";
import Voters from "@/views/pages/Election/Voter/Voters";
import VoterCreate from "@/views/pages/Election/Voter/VoterCreate";
import VoterImport from "@/views/pages/Election/Voter/VoterImport";
import VoterEdit from "@/views/pages/Election/Voter/VoterEdit";

import Emailing from "@/views/pages/Election/Emailing/Emailing.vue";



Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("access_token")) {
    return next()
  } else {
    return next("/")
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("access_token")) {
    return next()
  } else {
    return next("/login")
  }
};

const routes = [

  {
    path: "/login",
    name: 'Login',
    beforeEnter: guest,
    component: Login
  },
  {
    path: "/reset-password/:token",
    name: 'ResetPassword',
    beforeEnter: guest,
    component: ResetPassword
  },
  {
    path: "/logout",
    beforeEnter: auth,
    component: Logout
  },
  {
  path: "/",
  beforeEnter: auth,
  component: Home,
  children: [
    {
      path: "/",
      beforeEnter: auth,
      component: Dashboard
    },
    {
      path: "admin",
      beforeEnter: auth,
      component: Admins
    },
    {
      path: "admin/create",
      beforeEnter: auth,
      component: AdminCreate
    },
    {
      path: "admin/:admin_id/edit",
      beforeEnter: auth,
      component: AdminEdit
    },
    {
      path: "election",
      beforeEnter: auth,
      component: Elections
    },
    {
      path: "election/create",
      beforeEnter: auth,
      component: ElectionCreate
    },
    {
      path: "election/:election_id/edit",
      beforeEnter: auth,
      component: ElectionEdit
    },
    {
      path: "election/:election_id",
      beforeEnter: auth,
      component: ElectionBase,
      children:[
        {
          path: '',
          beforeEnter: auth,
          component: ElectionDetails,
          children:[]
        },


        {
          path: 'election-committee',
          beforeEnter: auth,
          component: ElectionCommittees
        },
        {
          path: 'election-committee/create',
          beforeEnter: auth,
          component: ElectionCommitteeCreate
        },
        {
          path: 'election-committee/:election_committee_id/edit',
          beforeEnter: auth,
          component: ElectionCommitteeEdit
        },


        {
          path: 'admin-election-committee',
          beforeEnter: auth,
          component: AdminsElectionCommittees
        },
        {
          path: 'admin-election-committee/create',
          beforeEnter: auth,
          component: AdminElectionCommitteeCreate
        },
        {
          path: 'admin-election-committee/:admin_election_committee_id/edit',
          beforeEnter: auth,
          component: AdminElectionCommitteeEdit
        },



        {
          path: 'candidate-list',
          beforeEnter: auth,
          component: CandidateLists
        },
        {
          path: 'candidate-list/create',
          beforeEnter: auth,
          component: CandidateListCreate
        },
        {
          path: 'candidate-list/:candidate_list_id/edit',
          beforeEnter: auth,
          component: CandidateListEdit
        },



        {
          path: 'voter',
          beforeEnter: auth,
          component: Voters
        },
        {
          path: 'voter/create',
          beforeEnter: auth,
          component: VoterCreate
        },
        {
          path: 'voter/import',
          beforeEnter: auth,
          component: VoterImport
        },
        {
          path: 'voter/:voter_id/edit',
          beforeEnter: auth,
          component: VoterEdit
        },


        {
          path: "emailing",
          beforeEnter: auth,
          component: Emailing,
        },


        {
          path: 'reports',
          beforeEnter: auth,
          // todo:: reports
        }
      ]
    },



    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes
});

export default router;
