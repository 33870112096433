<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import Forms from "@/components/form/Forms";
import toastr from "toastr";
import axios from "axios";

export default {
    name: "ElectionCommitteeForm",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        election_id: {
            default: null
        }
    },
    data(){
        return {
            settings: {},
            errors:{},
            route: '/election/' + this.election_id + '/voter',
            url: 'voter',
            input_data:{},

            election_committee_select: [],

            access_to_voting_select: [
                {
                    'key': '1',
                    'value': 'Da'
                },
                {
                    'key' : '0',
                    'value': 'Ne'
                }
            ],
        }
    },

    methods: {

        async init(){
            let vm = this

            vm.input_data = {}
            vm.setupForm()

        },


        async fetchElectionCommittee(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        election_id : vm.election_id,
                        constituency : '1'

                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.election_committee_select =
                        response.data.data.map( function( item ) {
                            return { key: item.id, value: item.name }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        getElectionCommitteeByID( id ){
            return this.election_committee_select.find( item => item.key === id )
        },

        getAccessToVotingByID( id ){
            return this.access_to_voting_select.find( item => item.key === id )
        },



        setupForm(){
            let vm = this

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route
                }
            }


            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url + '/import'
                }
            }

            let buttons = []



            buttons.push( button_cancel )
            buttons.push( button_save )


            let inputs = []

            let election_id_input = {
                name: 'election_id',
                type: 'hidden',
                title: 'Naziv',
                value: vm.election_id,
                width: 12,
                error: vm.errors['election_id'] ? vm.errors['election_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( election_id_input )


            let election_cimmittee_id_input = {
                name: 'election_committee_id',
                type: 'select',
                title: 'Izborno povjerenstvo',
                value: vm.input_data.election_committee_id ? vm.getElectionCommitteeByID( vm.input_data.election_committee_id ) : '',
                width: 12,
                error: vm.errors['election_committee_id'] ? vm.errors['election_committee_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.election_committee_select
                }
            }
            inputs.push( election_cimmittee_id_input )

            let access_to_voting_input = {
                name: 'access_to_voting',
                type: 'select',
                title: 'Pravo glasanja',
                value: vm.input_data.access_to_voting ? vm.getAccessToVotingByID( vm.input_data.access_to_voting ) : '',
                width: 12,
                error: vm.errors['access_to_voting'] ? vm.errors['access_to_voting'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.access_to_voting_select
                }
            }
            inputs.push( access_to_voting_input )


            let group_name_input = {
                name: 'group_name',
                type: 'input',
                title: 'Grupa',
                value: vm.input_data.group_name,
                width: 12,
                error: vm.errors['group_name'] ? vm.errors['group_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {}
            }
            inputs.push( group_name_input )


            inputs.push(
                {
                    name: 'files',
                    type: 'upload',
                    title: 'Datoteka',
                    value: vm.input_data['files'] ? vm.input_data['files'] : '',
                    width: 12,
                    error: vm.errors['files'] ? vm.errors['files'] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 2,
                        input_width: 10
                    }
                }
            )


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            if( this.input_data[ name ] !== value ){
                this.input_data[ name ] = value
                this.setupForm()
            }
        },


        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' ){
                if( data.success){
                    toastr.success( 'Uspješno spremljeno!' )
                    this.$router.push({ path: this.route  } )
                } else {
                    if( data.messages.election_id )
                        toastr.error( data.messages.election_id )
                    this.errors = data.messages
                    this.setupForm()
                }
            }

        },
    },

    async mounted() {
        await this.fetchElectionCommittee()
        await this.init()
    }
};
</script>

<style scoped>

</style>
