<template>
    <div>
        <breadcrumbs
            :title="'Izbori'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>

        <div class="ibox">

            <div class="ibox-title">
                <h5>Izbori</h5><br>
                <button
                    v-if="admin && [2,3].includes( admin.admin_permission_id )"
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi izbori
                </button>
            </div>

            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                ></datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";

export default {
    name: "Elections",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            breadcrumbs: [],

            admin: null,

            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'election',
            datatables: {},
        }
    },

    methods: {

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/admins',
                    name: 'Izbori'
                }
            ]
        },

        create(){
            this.$router.push( { path: '/election/create' } )
        },

        rowClick( row ){
            this.$router.push( { path: '/election/' + row.id } )
        },

        async getAdmin(){
            let vm = this
            await axios.get(process.env.VUE_APP_API_URL + "admin-auth" )
                .then(response => { vm.admin = response.data })
                .catch(() => { vm.admin = null });
        },

        async setupDatatable() {

            let vm = this;

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'title': 'ID',
                            'visible': false,
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'election',
                            }
                        },
                        {
                            'id': 'name',
                            'title': 'Izbori',
                            'column_visibility_group': {
                                id: 'election',
                            }
                        },
                        {
                            'id': 'voting_date_start',
                            'title': 'Datum i vrijeme početka glasnja',
                            'column_visibility_group': {
                                id: 'election',
                            },
                            'filter': {
                                'type': 'date'
                            }
                        },
                        {
                            'id': 'voting_date_end',
                            'title': 'Datum i vrijeme završetka glasnja',
                            'column_visibility_group': {
                                id: 'election',
                            },
                            'filter': {
                                'type': 'date'
                            }
                        },
                    ],
                    column_visibility_groups:{
                        'user' : 'Izbori'
                    },
                    order: 'desc',
                    order_by: 'id',
                    enable_export: false
                },
                title: 'Izbori',
            }

            vm.show_datatable = true;

        }

    },
    async mounted() {
    this.setBreadcrumbs()
    await this.getAdmin()
    await this.setupDatatable()
    }
};
</script>

<style scoped>

</style>
