<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>

    </div>
</template>

<script>
import Forms from "@/components/form/Forms";
import toastr from "toastr";
import axios from "axios";

export default {
    name: "ElectionCommitteeForm",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        election_id: {
            default: null
        }
    },
    data(){
        return {
            settings: {},
            errors:{},
            route: '/election/' + this.election_id + '/election-committee',
            url: 'election-committee',

            input_data:{},

            election_committee_parent_select: [],

            constituency_select: [
                {
                    'key': '1',
                    'value': 'Da'
                },
                {
                    'key' : '0',
                    'value': 'Ne'
                }
            ]
        }
    },

    methods: {

        async init(){
            let vm = this

            if ( vm.action === 'edit' || vm.action === 'show' ){

                await axios.get( process.env.VUE_APP_API_URL + 'election-committee/' + vm.id,)
                    .then( response => {
                        vm.input_data['name'] = response.data.name
                        vm.input_data['constituency'] = response.data.constituency.toString()
                        vm.input_data['constituency_name'] = response.data.constituency_name
                        vm.input_data['parent_id'] = response.data.parent_id
                        vm.input_data['ballot_name'] = response.data.ballot ? response.data.ballot.name : ''
                        vm.input_data['ballot_label'] = response.data.ballot ? response.data.ballot.label : ''
                        vm.input_data['ballot_header'] = response.data.ballot ? response.data.ballot.header : ''
                        vm.input_data['ballot_title'] = response.data.ballot ? response.data.ballot.title : ''
                        vm.input_data['ballot_content'] = response.data.ballot ? response.data.ballot.content : ''
                        vm.input_data['ballot_instructions'] = response.data.ballot ? response.data.ballot.instructions : ''
                        vm.input_data['ballot_gdpr'] = response.data.ballot ? response.data.ballot.gdpr : ''
                        vm.input_data['no_of_candidates_per_list'] = response.data.ballot ? response.data.ballot.gdpr : ''
                        vm.input_data.no_of_candidates_per_list = response.data.settings
                            ? response.data.settings.no_of_candidates_per_list
                            : ''
                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( error => { console.log( error ) } )

            } else if ( vm.action === 'create' ) {
                vm.input_data = {}
                vm.setupForm()
            }
        },


        async fetchElectionCommitteeParent(){
            let vm = this;
            await axios.get( process.env.VUE_APP_API_URL + 'election-committee', {
                params: {
                    page: 1,
                    per_page: -1,
                    order_by: 'id',
                    order: 'desc',
                    filters: {
                        election_id : vm.election_id,
                        constituency : '0'

                    },
                    options: {},
                }

            } )
                .then( response => {
                    vm.election_committee_parent_select =
                        response.data.data.map( function( item ) {
                            return { key: item.id, value: item.name }
                        })
                } )
                .catch( error => { console.log( error ) } )
        },

        getElectionCommitteeParentByID( id ){
            return this.election_committee_parent_select.find( item => item.key === id )
        },

        getConstituencyByID( id ){
            return this.constituency_select.find( item => item.key === id )
        },


        setupForm(){
            let vm = this

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }


            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_delete )
                buttons.push( button_update)
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = []

            let election_id_input = {
                name: 'election_id',
                type: 'hidden',
                title: 'Naziv',
                value: vm.election_id,
                width: 12,
                error: vm.errors['election_id'] ? vm.errors['election_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                }
            }
            inputs.push( election_id_input )

            let name_input = {
                name: 'name',
                type: 'input',
                title: 'Naziv',
                value: vm.input_data.name,
                width: 12,
                error: vm.errors['name'] ? vm.errors['name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 4
                }
            }
            inputs.push( name_input )

            let constituency_input = {
                name: 'constituency',
                type: 'select',
                title: 'Izborna jedinica',
                value: vm.input_data.constituency ? vm.getConstituencyByID( vm.input_data.constituency ) : '',
                width: 12,
                error: vm.errors['constituency'] ? vm.errors['constituency'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disable,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.constituency_select,
                    title_width: 2,
                    input_width: 4
                }
            }
            inputs.push( constituency_input )

            let constituency_name_input = {
                name: 'constituency_name',
                type: 'input',
                title: 'Naziv izborne jedinice',
                value: vm.input_data.constituency_name,
                width: 12,
                error: vm.errors['constituency_name'] ? vm.errors['constituency_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 4
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( constituency_name_input )

            let no_of_candidates_per_list_input = {
                name: 'no_of_candidates_per_list',
                type: 'input',
                title: 'Broj kandidata na listama',
                value: vm.input_data.no_of_candidates_per_list,
                width: 12,
                error: vm.errors['no_of_candidates_per_list'] ? vm.errors['no_of_candidates_per_list'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 4
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( no_of_candidates_per_list_input )


            let parent_id_input = {
                name: 'parent_id',
                type: 'select',
                title: 'Nadležno izborno povjerenstvo',
                value: vm.input_data.parent_id ? vm.getElectionCommitteeParentByID( vm.input_data.parent_id ) : '',
                width: 12,
                error: vm.errors['parent_id'] ? vm.errors['parent_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.election_committee_parent_select,
                    title_width: 2,
                    input_width: 4
                }
            }

            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( parent_id_input )



            let ballot_name_input = {
                name: 'ballot_name',
                type: 'input',
                title: 'Naziv listića',
                value: vm.input_data.ballot_name,
                width: 12,
                error: vm.errors['ballot_name'] ? vm.errors['ballot_name'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 4
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_name_input )



            let ballot_label_input = {
                name: 'ballot_label',
                type: 'input',
                title: 'Oznaka listića',
                value: vm.input_data.ballot_label,
                width: 12,
                error: vm.errors['ballot_label'] ? vm.errors['ballot_label'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 4
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_label_input )



            let ballot_header_input = {
                name: 'ballot_header',
                type: 'editor',
                title: 'Header',
                value: vm.input_data.ballot_header,
                width: 12,
                error: vm.errors['ballot_header'] ? vm.errors['ballot_header'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_header_input )



            let ballot_title_input = {
                name: 'ballot_title',
                type: 'editor',
                title: 'Naslov',
                value: vm.input_data.ballot_title,
                width: 12,
                error: vm.errors['ballot_title'] ? vm.errors['ballot_title'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_title_input )



            let ballot_content_input = {
                name: 'ballot_content',
                type: 'editor',
                title: 'Tekst',
                value: vm.input_data.ballot_content,
                width: 12,
                error: vm.errors['ballot_content'] ? vm.errors['ballot_content'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_content_input )



            let ballot_instructions_input = {
                name: 'ballot_instructions',
                type: 'editor',
                title: 'Upute',
                value: vm.input_data.ballot_instructions,
                width: 12,
                error: vm.errors['ballot_instructions'] ? vm.errors['ballot_instructions'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_instructions_input )

            let ballot_gdpr_input = {
                name: 'ballot_gdpr',
                type: 'editor',
                title: 'GDPR privola',
                value: vm.input_data.ballot_gdpr,
                width: 12,
                error: vm.errors['ballot_gdpr'] ? vm.errors['ballot_gdpr'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }
            if( this.input_data.constituency && this.input_data.constituency === '1' )
                inputs.push( ballot_gdpr_input )





            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            if( this.input_data[ name ] !== value ){
                this.input_data[ name ] = value
                this.setupForm()
            }
        },


        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route  } )
                } else {
                    if( data.messages.election_id )
                        toastr.error( data.messages.election_id )
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                if( data.success ){
                    toastr.error( 'Uspješno obrisano!' )
                    this.$router.push({ path: this.route } )
                } else {
                    if( data.messages.election_id )
                        toastr.error( data.messages.election_id )
                    else
                        toastr.error( 'Došlo je do greške' )
                }

            }

        },
    },

    async mounted() {
        await this.fetchElectionCommitteeParent()
        await this.init()
    }
};
</script>

<style scoped>

</style>
