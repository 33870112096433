<template>
    <div>
        <election-navigation
            :id="election_id"
            :breadcrumbs="breadcrumbs"
            :admin="admin"
        ></election-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Novi glasač</h5>
            </div>
            <div class="ibox-content">
                <voter-import-form
                    :disabloed="false"
                    :id="null"
                    :modal="false"
                    :action="'import'"
                    :election_id="election_id"
                ></voter-import-form>
            </div>
        </div>

    </div>
</template>

<script>
import ElectionNavigation from "@/views/pages/Election/ElectionNavigation";
import VoterImportForm from "@/views/pages/Election/Voter/VoterImportForm";
export default {
    name: "VoterImport",
    components: { VoterImportForm, ElectionNavigation },
    props: ['admin'],
    data(){
        return {
            election_id: this.$route.params.election_id,
            breadcrumbs: []
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/election/' + this.election_id + '/voter',
                    name: 'Popis glasača'
                },
                {
                    path: '/election/' + this.election_id + '/voter/import',
                    name: 'Uvoz novih glasača'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
